import React from "react";
import ThomasLogo from "../assets/img/thomas_logo.svg";

export class Header extends React.Component {
  render() {
    return (<div style={styles.header}>
        <a href="https://www.thomasinternational.net/pt-br/" target={'_blank'}>
          <img src={ThomasLogo} alt="Thomas" style={styles.img}/>
        </a>
      </div>
    );
  }
}

export const styles = {
  header: {
    paddingTop: '25px',
    paddingBottom: '25px'
  },
  img: {
    width: '175px'
  }
};
